import { useCallback, useEffect, useRef } from 'react';
import { ICallableRef, IOutputValueType } from '@/base/FormGenerator';
import CuForm from '@/components/CuForm';
import {
    createApplicationSetting,
    fetchApplicationsSettingsItems,
    updateApplicationSetting
} from '@/data/ApplicationSettingsItems/ApplicationSettingsItemActions';
import { ISchedulePlanApplicationSettingItem } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemModels';
import { getSchedulePlanApplicationsSettings } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { getSettingsItemBooleanValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import useAppTranslation from '@/hooks/useAppTranslation';
import ApplicationSettingsSchedulePlanItemEnum from '@/utils/enums/ApplicationSettings/ApplicationSettingsSchedulePlanItemEnum';
import ApplicationSettingsItemTypesEnum from '@/utils/enums/ApplicationSettingsItemTypesEnum';
import { PermissionsControllerEnum } from '@/utils/enums/PermissionsEnum';

const SchedulePlanApplicationSettingsForm = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(getSchedulePlanApplicationsSettings);
    const formRef = useRef<ICallableRef | null>(null);

    const handleData = useCallback(
        (source: IOutputValueType): ISchedulePlanApplicationSettingItem[] =>
            [
                {
                    application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                    key: 'on_holiday_insert_set_shift_as_empty',
                    value: source.on_holiday_insert_set_shift_as_empty as boolean
                },
                {
                    application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                    key: 'dnd_shift_between_days',
                    value: source.dnd_shift_between_days as boolean
                },
                {
                    application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                    key: 'can_take_trade_without_counteroffer',
                    value: source.can_take_trade_without_counteroffer as boolean
                }
            ] as ISchedulePlanApplicationSettingItem[],
        []
    );

    const handleSubmit = useCallback(
        (values: IOutputValueType) =>
            updateApplicationSetting({
                id: ApplicationSettingsItemTypesEnum.schedulePlan,
                data: {
                    application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                    value: handleData(values)
                }
            }),
        []
    );

    const handleCreate = useCallback(
        (values: IOutputValueType) =>
            createApplicationSetting({
                application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                value: handleData(values)
            }),
        []
    );

    const handleOpen = useCallback(() => dispatch(fetchApplicationsSettingsItems()), []);

    //!!!!Důležité tu mít toto protože pokud paralerne s tímto jede jiny test který ve storu ma uložene staré hodnoty, tak je vymaže pod rukama souběžně běžícímu testu !!!!!
    useEffect(() => {
        formRef.current?.setFieldValue(
            'on_holiday_insert_set_shift_as_empty',
            getSettingsItemBooleanValueByKey(
                data,
                ApplicationSettingsSchedulePlanItemEnum.onHolidayInsertSetShiftAsEmpty
            )
        );
        formRef.current?.setFieldValue(
            'dnd_shift_between_days',
            getSettingsItemBooleanValueByKey(data, ApplicationSettingsSchedulePlanItemEnum.dndShiftBetweenDays)
        );
        formRef.current?.setFieldValue(
            'can_take_trade_without_counteroffer',
            getSettingsItemBooleanValueByKey(
                data,
                ApplicationSettingsSchedulePlanItemEnum.canTakeTradeWithoutCounteroffer
            )
        );
    }, [formRef.current, data]);

    return (
        <CuForm
            innerRef={formRef}
            resource={PermissionsControllerEnum.SettingsItems}
            name="schedulePlanApplicationSettingsForm"
            id={data.length > 0 ? ApplicationSettingsItemTypesEnum.schedulePlan : undefined}
            displayAsSidebar
            displayAsModal={false}
            onOpen={handleOpen}
            maxWidth="xl"
            title={t('label.schedulePlanSetting', 'Schedule Plan Setting')}
            openButtonValue={t('label.update', 'Update')}
            items={[
                {
                    type: 'switch',
                    props: {
                        name: 'on_holiday_insert_set_shift_as_empty',
                        label: t('label.onHolidayInsertSetShiftAsEmpty', 'On Holiday Insert Set Shift As Empty')
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'dnd_shift_between_days',
                        label: t(
                            'label.dndShiftBetweenDays',
                            'When Drag & Drop Shift between dates move Original Shift into Empty Shift'
                        )
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'can_take_trade_without_counteroffer',
                        label: t('label.canTakeTradeWithoutCounteroffer', 'Can take trade without counteroffer')
                    }
                }
            ]}
            onSubmitUpdate={handleSubmit}
            onSubmitCreate={handleCreate}
        />
    );
};

export default SchedulePlanApplicationSettingsForm;
