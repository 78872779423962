import { useCallback, useEffect, useRef } from 'react';
import { ICallableRef } from '@/base/FormGenerator';
import CuForm, { IOutputValueType } from '@/components/CuForm';
import {
    createApplicationSetting,
    fetchApplicationsSettingsItems,
    updateApplicationSetting
} from '@/data/ApplicationSettingsItems/ApplicationSettingsItemActions';
import { IRequestApplicationSettingItem } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemModels';
import { getRequestApplicationsSettings } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { getSettingsItemBooleanValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import useAppTranslation from '@/hooks/useAppTranslation';
import ApplicationSettingsRequestItemEnum from '@/utils/enums/ApplicationSettings/ApplicationSettingsRequestItemEnum';
import ApplicationSettingsItemTypesEnum from '@/utils/enums/ApplicationSettingsItemTypesEnum';
import { PermissionsControllerEnum } from '@/utils/enums/PermissionsEnum';

const RequestApplicationSettingsForm = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(getRequestApplicationsSettings);
    const formRef = useRef<ICallableRef | null>(null);

    const handleData = useCallback(
        (source: IOutputValueType): IRequestApplicationSettingItem[] =>
            [
                {
                    application_key: ApplicationSettingsItemTypesEnum.request,
                    key: ApplicationSettingsRequestItemEnum.allowCreateNewRequestAfterExceedUsersRequestFund,
                    value: source.allow_create_new_request_after_exceed_users_request_fund as boolean
                },
                {
                    application_key: ApplicationSettingsItemTypesEnum.request,
                    key: ApplicationSettingsRequestItemEnum.allowCreateNewVacationAfterExceedUsersRequestFund,
                    value: source.allow_create_new_vacation_after_exceed_users_request_fund as boolean
                }
            ] as IRequestApplicationSettingItem[],
        []
    );

    const handleSubmit = useCallback(
        (values: IOutputValueType) =>
            updateApplicationSetting({
                id: ApplicationSettingsItemTypesEnum.request,
                data: {
                    application_key: ApplicationSettingsItemTypesEnum.request,
                    value: handleData(values)
                }
            }),
        []
    );

    const handleCreate = useCallback(
        (values: IOutputValueType) =>
            createApplicationSetting({
                application_key: ApplicationSettingsItemTypesEnum.request,
                value: handleData(values)
            }),
        []
    );

    const handleOpen = useCallback(() => dispatch(fetchApplicationsSettingsItems()), []);

    //!!!!Důležité tu mít toto protože pokud paralerne s tímto jede jiny test který ve storu ma uložene staré hodnoty, tak je vymaže pod rukama souběžně běžícímu testu !!!!!
    useEffect(() => {
        formRef.current?.setFieldValue(
            ApplicationSettingsRequestItemEnum.allowCreateNewRequestAfterExceedUsersRequestFund,
            getSettingsItemBooleanValueByKey(
                data,
                ApplicationSettingsRequestItemEnum.allowCreateNewRequestAfterExceedUsersRequestFund
            )
        );
        formRef.current?.setFieldValue(
            ApplicationSettingsRequestItemEnum.allowCreateNewVacationAfterExceedUsersRequestFund,
            getSettingsItemBooleanValueByKey(
                data,
                ApplicationSettingsRequestItemEnum.allowCreateNewVacationAfterExceedUsersRequestFund
            )
        );
    }, [formRef.current, data]);

    return (
        <CuForm
            innerRef={formRef}
            name="requestApplicationSettingsForm"
            resource={PermissionsControllerEnum.SettingsItems}
            id={data.length > 0 ? ApplicationSettingsItemTypesEnum.request : undefined}
            displayAsSidebar
            displayAsModal={false}
            onOpen={handleOpen}
            maxWidth="xl"
            title={t('label.requestSetting', 'Request Setting')}
            openButtonValue={t('label.update', 'Update')}
            items={[
                {
                    type: 'switch',
                    props: {
                        name: ApplicationSettingsRequestItemEnum.allowCreateNewRequestAfterExceedUsersRequestFund,
                        label: t(
                            'label.allowCreateNewRequestAfterExceedUsersRequestFund',
                            'Allow Create New Request After Exceed Users Request Fund'
                        )
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: ApplicationSettingsRequestItemEnum.allowCreateNewVacationAfterExceedUsersRequestFund,
                        label: t(
                            'label.allowCreateNewVacationAfterExceedUsersRequestFund',
                            'Allow Create New Vacation After Exceed Users Request Fund'
                        )
                    }
                }
            ]}
            onSubmitUpdate={handleSubmit}
            onSubmitCreate={handleCreate}
        />
    );
};

export default RequestApplicationSettingsForm;
